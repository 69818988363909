import * as Sentry from '@sentry/sveltekit';
import { isProduction } from '$helpers';
import { getGdprPref } from '$helpers/gdpr';

Sentry.init({
  dsn: 'https://3963051dd959411381fd6c27187a40f4@o1152781.ingest.us.sentry.io/4504005433360384',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
  integrations: [Sentry.replayIntegration()],
  environment: isProduction ? 'production' : 'development',
  beforeSend: (event) => {
    const allowsTracking = getGdprPref('errorTracking');
    return allowsTracking ? event : null;
  },
});

export const handleError = Sentry.handleErrorWithSentry();
